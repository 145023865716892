import ScrollAnimation from '../../../../utils/scrollAnimation';
import separator from '../../../../assets/imgs/separator.svg';

const BodyRitual = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='bodyritual'>
      <div className='spa_about_img spa_about_img_rotate'>
        <img src={separator} alt='separator' />
      </div>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='title_underline_dark'>{lang.spa.bodyritual.title}</h2>
          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
              <p>{lang.spa.bodyritual.mediterranean.title}</p>
              <span>{lang.spa.bodyritual.mediterranean.subtitle}</span>
              <ul>
                <li>80min - 145€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.bodyritual.detox.title}</p>
              <span>{lang.spa.bodyritual.detox.subtitle}</span>
              <ul>
                <li>80min - 120€</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
              <p>{lang.spa.bodyritual.scrub.title}</p>
              <span>{lang.spa.bodyritual.scrub.subtitle}</span>
              <ul>
                <li>80min - 48€</li>
              </ul>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
      <div className='spa_about_img'>
        <img src={separator} alt='separator' />
      </div>
    </div>
  );
};

export default BodyRitual;
