import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import ScrollAnimation from '../../utils/scrollAnimation';
import About from './components/about/About';
import Masssages from './components/massages/Masssages';
import Treatments from './components/treatments/Treatments';
import Wax from './components/wax/Wax';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import Gallery from '../home/components/gallery/Gallery';
import BodyRitual from './components/bodyRitual/BodyRitual';
import Facial from './components/facial/Facial';

const images = require.context('../../assets/imgs/spa', true);
const imageList = images.keys().map((image: any) => images(image));

const Spa = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.PROPERTY_NAME
    )} ${CapitalizeFirstLeter(Constants.SPA)}`;
  }, []);

  return (
    <div id={Constants.SPA} className='spa_wrapper'>
      <div className='spa_hero'>
        <img src={imageList[4]} alt={`${lang.spa.subtitle} hero`} />
        <div className='spa_hero_title'>
          <h1>{lang.spa.subtitle}</h1>
        </div>
      </div>
      <About lang={lang} />
      <Masssages lang={lang} />
      <BodyRitual lang={lang} />
      <Facial lang={lang} />
      <Wax lang={lang} />
      <Treatments lang={lang} />
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
        <div className='spa_container container'>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <p>* {lang.spa.p2}</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
            <p>{lang.spa.p3}</p>
          </ScrollAnimation>
        </div>
      </ScrollAnimation>
      <Gallery lang={lang} imageList={imageList} />
    </div>
  );
};

export default Spa;
