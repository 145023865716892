import ScrollAnimation from '../../../../utils/scrollAnimation';

const Facial = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='facial'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='title_underline_dark'>{lang.spa.facial.title}</h2>
          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
              <p>{lang.spa.facial.retreat.title}</p>
              <span>{lang.spa.facial.retreat.subtitle}</span>
              <ul>
                <li>90min - 180€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.facial.exceptional.title}</p>
              <span>{lang.spa.facial.exceptional.subtitle}</span>
              <ul>
                <li>80min - 160€</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
              <p>{lang.spa.facial.silicium.title}</p>
              <span>{lang.spa.facial.silicium.subtitle}</span>
              <ul>
                <li>70min - 155€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
              <p>{lang.spa.facial.hyalu.title}</p>
              <span>{lang.spa.facial.hyalu.subtitle}</span>
              <ul>
                <li>60min - 145€</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={250}>
              <p>{lang.spa.facial.energizing.title}</p>
              <span>{lang.spa.facial.energizing.subtitle}</span>
              <ul>
                <li>50min - 120€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={300}>
              <p>{lang.spa.facial.hydra.title}</p>
              <span>{lang.spa.facial.hydra.subtitle}</span>
              <ul>
                <li>50min - 100€</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={250}>
              <p>{lang.spa.facial.beauty.title}</p>
              <span>{lang.spa.facial.beauty.subtitle}</span>
              <ul>
                <li>40min - 60€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={300}>
              <p>{lang.spa.facial.additional.title}</p>
              <ul>
                <li>{lang.spa.facial.additional.l1} 15min - 20€</li>
                <li>{lang.spa.facial.additional.l2} 20min - 40€</li>
              </ul>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Facial;
