import Constants from '../utils/Constants';

export const hr: Locale.Keys = {
  lang: Constants.lang.hr,
  menu: {
    ourStory: 'NAŠA PRIČA',
    roomsSuites: 'SOBE & APARTMANI',
    stayingCora: 'OTKRIJTE BEZVREMENSKI ŠARM',
    coraCaffe: 'CORA CAFFE',
    spa: 'WELLNESS & SPA',
    meetingsEvents: 'KONFERENCIJE & DOGAĐANJA - Busy Cora',
    offers: 'POSEBNE PONUDE',
    gallery: 'GALERIJA',
    contactUs: 'KONTAKTIRAJTE NAS',
    bookNow: 'REZERVIRAJ',
  },
  about: {
    title: 'Dobrodošli u Cora Hotel',
    p1: 'Cora, intimni hotel s 34 sobe, sofisticiran je spoj udobnosti i urbane dinamike. Smješten u mirnom dijelu grada udaljenom samo nekoliko minuta vožnje od centra, Cora je nadomak svega što želite vidjeti i doživjeti u drevnom Splitu.',
    p2: 'Izgrađen 1973. godine, hotelski objekt je prošao kroz temeljitu renovaciju i nudi vam elegantni, topli i ugodni ambijent. S ciljem stvaranja ugođaja koji podiže iskustvo boravka na višu razinu, personalizirana usluga i zadovoljstvo gostiju glavni su prioriteti našeg gostoljubivog osoblja.',
  },
  services: {
    title: 'SADRŽAJ I USLUGE',
    frontDesk: 'RECEPCIJA 24/7',
    breakfast: 'RASKOŠAN DORUČAK',
    rooftop: 'ROOFTOP BAR',
    spa: 'WELLNESS & SPA',
    gym: 'TERETANA',
    conference: 'KONFERENCIJSKA SALA',
    wheelchair: 'PRISTUP ZA OSOBE S INVALIDITETOM',
    pet: 'KUĆNI LJUBIMCI SU DOBRODOŠLI',
    parking: 'PARKING',
    eCharging: 'PUNIONICA ZA ELEKTRIČNE AUTOMOBILE',
  },
  roomsAndSuites: {
    title: 'SOBE I APARTMANI',
    subtitle: 'VAŠA PRIVATNA OAZA LUKSUZA',
    p1: 'Svaka soba i apartman nudi profinjenu, rezidencijalnu atmosferu upotpunjenu najnovijom tehnologijom, udobnim namještajem i izuzetnom pažnjom posvećenom detaljima.',
    comfortTitle: 'Standard',
    comfort: 'Standardna Soba',
    superiorCourtyardTitle: 'Superiror I',
    superiorCourtyard: 'Superior Dvokrevetna Soba s Pogledom na Dvorište',
    superiorStreetTitle: 'Superior II',
    superiorStreet: 'Superior Dvokrevetna Soba s Pogledom na Gradsku Ulicu',
    suiteTitle: 'Apartmani',
    suite: 'Junior Apartman',
    showMore: 'POKAŽI VIŠE',
  },
  comfortRoom: {
    title: 'STANDARDNA SOBA',
    subtitle: 'standard',
    p1: 'Ukusno namještene s intimnim, elegantnim ugođajem, sobe u Cori su idealna baza za istraživanje živahnog susjedstva.',
  },
  superiorCourtyard: {
    title: 'SUPERIOR DVOKREVETNA SOBA S POGLEDOM NA DVORIŠTE',
    subtitle: 'superior I',
    p1: 'Ukusno namještene s intimnim, elegantnim ugođajem, sobe u Cori su idealna baza za istraživanje živahnog susjedstva.',
  },
  superiorStreet: {
    title: 'SUPERIOR DVOKREVETNA SOBA S POGLEDOM NA GRADSKU ULICU',
    subtitle: 'superior II',
    p1: 'Ukusno namještene s intimnim, elegantnim ugođajem, sobe u Cori su idealna baza za istraživanje živahnog susjedstva.',
  },
  juniorSuite: {
    title: 'JUNIOR APARTMAN',
    subtitle: 'apartmani',
    p1: 'Nježne boje i pažljivo osmišljeni detalji upotpunjuju dojam modernog luksuza u svijetlim ,prozračnim apartmanima.',
  },
  stayingAtCora: {
    title: 'OTKRIJTE BEZVREMENSKI ŠARM',
    restaurantTitle: 'GASTRONOMIJA',
    restaurant:
      '...udobno se smjestite u Delicious Cora suvremenom restoranu s elegantnim interijerom i jelima nadahnutim bogatom raznolikošću svježih, lokalnih mediteranskih proizvoda.\n\nDORUČAK\nod 06:30 do 10:30 h\n\nAutentičnost i izuzetni okusi glavna su obilježja prepoznatljivih regionalnih jela koja su sastavni dio našeg raskošnog doručka, zajedno sa nizom specijaliteta i okusa iz cijeloga svijeta.',
    spaTitle: 'WELLNESS & SPA',
    spa: '...hotelski spa predstavlja mirni, opuštajući prostor sa sobama za tretmane, hidromasažnom kadom, saunom i malom teretanom.',
    conferenceTitle: 'KONFERENCIJSKA DVORANA',
    conference:
      '...idealna za sastanke, manje konferencije ili elegantna privatna okupljanja, konferencijska sala nudi okruženje i uslugu koja osigurava da svaki događaj ostane nezaboravan.',
    rooftopTitle: 'CORA CAFFE',
    rooftop:
      '…hotelski Rooftop bar, do kojega se dolazi panoramskim dizalom, najšarmantnija je značajka hotela. Uživajte u raznolikoj ponudi napitaka, pića s potpisom i pogledu koji se proteže na obližnje planine s jedne strane i živahno susjedstvo s druge.',
  },
  coraCaffe: {
    title: 'CORA CAFFE ROOFTOP BAR',
    subtitle: 'CORA CAFFE',
    p1: 'Hotelski Rooftop bar, do kojega se dolazi panoramskim dizalom, najšarmantnija je značajka hotela. Uživajte u raznolikoj ponudi napitaka, pića s potpisom i pogledu koji se proteže na obližnje planine s jedne strane i živahno susjedstvo s druge.',
    ul1: 'Radno vrijeme:',
    li1: 'Ponedjeljak - Nedjelja',
    p2: 'Besplatan parking 2 sata za goste Cora Caffe Rooftop Bara',
    p3: 'Cora Caffe Meni',
    offHoursMessage: {
      header: 'Dragi gosti,',
      p1: 'Tijekom zimske sezone Cora Caffe rooftop bar je privremeno zatvoren.',
      p2: 'Rado ćemo Vas ugostiti u našem baru u prizemlju Cora hotela.',
      footer: 'Cora team',
    },
  },
  spa: {
    title: 'OPUSTITE SE I POMLADITE',
    subtitle: 'WELLNESS & SPA',
    button: 'POGLEDAJ MENI',
    p1: 'Uživajte u luksuzu naše mirne spa oaze dok se prepuštate našem nizu umirujućih spa tretmana. Dopustite stručnim rukama naših terapeuta da vas vode kroz putovanje opuštanja, omogućujući vam da pomladite ne samo svoje tijelo već i svoj um i dušu.',
    p2: 'Wellness sadržaji za goste hotela (finska sauna, jacuzzi) prema rezervaciji i radnom vremenu. Potrebna rezervacija minimalno 45min prije tretmana. Molimo da unaprijed kontaktirate naše osoblje na racepciji ako želite koristiti wellness sadržaje izvan radnog vremena.',
    p3: 'Cijene su izražene u hrvatskoj valuti kunama i eurima prema fiksnom tečaju 1EUR = 7,53450HRK (zaokruženo na 2 decimalna mjesta) definirano Uredbom Vijeća (EU) 2022/1208 od 12. srpnja 2022.',
    massage: {
      title: 'Masaže',
      body: {
        title: 'Rituali za tijelo',
        subtitle: '',
      },
      muscle: {
        title: 'Distres masaža mišića',
        subtitle:
          'Ovo su usluge osmišljene za poticanje cjelokupnog zdravlja i smanjenja stresa, napetosti i bolova u mišićima.',
      },
      cora: {
        title: 'Cora Masaža',
        subtitle:
          'Relaksirajuća masaža koja koristi ulja za poticanje opuštanja i ravnoteže u tijelu i umu.',
      },
      additional: {
        title: 'Dodatna usluga masaže',
        l1: 'Masaža lica',
        l2: 'Masaža stopala',
        l3: 'Masaža vlasišta',
      },
    },
    bodyritual: {
      title: 'RITUAL TIJELA',
      mediterranean: {
        title: 'Mediteranski ritual',
        subtitle:
          'Povucite se uz opuštanje pilingom i jedinstvenom tehnikom masaže, baš poput mediteranskog stila života.',
      },
      detox: {
        title: 'Ritual detoksikacije',
        subtitle:
          'Očistite i pročistite svoje tijelo tretmanom detoksikacije. Posebno blato ili glina, morska sol i vitamini izvlače toksine i vraćaju ravnotežu vašoj koži. Nakon svega toga uslijedila je masaža cijelog tijela.',
      },
      scrub: {
        title: 'Piling za tijelo',
        subtitle:
          'Piling će nježno ukloniti mrtve stanice kože, potaknuti regeneraciju novih stanica i savršeno pripremiti kožu.',
      },
    },
    facial: {
      title: 'LICA',
      retreat: {
        title: 'Retreat tretman lica ultrazvučnom lopaticom',
        subtitle:
          'Cora luksuzna njega lica jedinstveni je tretman za njegu lica koji obično uključuje niz koraka osmišljenih za dubinsko čišćenje i piling kože ultrazvučnom špatulom. Također, hidratizira i pomlađuje kožu s učinkom liftinga i jača kožu.',
      },
      exceptional: {
        title: 'Izvanredna njega lica',
        subtitle:
          'Morski "lifting" tretman koji redefinira konture lica i vraća gustoću kože zahvaljujući kombinaciji patentiranog kompleksa morskog obnavljanja i ekskluzivne Energilift tehnike masaže.',
      },
      silicium: {
        title: 'Silikon za lice',
        subtitle:
          'Ovaj anti-aging tretman inspiriran je fitnesom za lice i kombinira ekskluzivnu tehniku masaže s profesionalnom cryo-učvršćujućom dvostrukom maskom za učvršćivanje kože, popunjavanje bora i osvježenje tena u samo 60 minuta.',
      },
      hyalu: {
        title: 'Hyalu-procollagene tretman lica',
        subtitle:
          'Tretman od bora do bora koji kombinira hijaluronsku kiselinu i morski pro-kolagen s Roller Boosterom. Trenutačno lice izgleda mlađe, kao opušteno, a čak i uočene bore izgledaju izglađene.',
      },
      energizing: {
        title: 'Energiziranje lica',
        subtitle:
          'Morska fotografija za urbanu kožu! Detoksikacijski tretman za osvježenje lica, vraćanje svježeg tena i glatke kože.',
      },
      hydra: {
        title: 'Hydra tretman lica',
        subtitle:
          'Thalgova prepoznatljiva njega lica inspirirana morem koncentrira dobrobiti oceana kako bi pomogla obnoviti savršenu kvalitetu kože, pružajući intenzivnu i dugotrajnu hidrataciju.',
      },
      beauty: {
        title: 'Radost ljepote',
        subtitle:
          'Ovi tretmani izvrsni su za sve tipove kože i otkrit će zdraviji i blistaviji ten kože.',
      },
      additional: {
        title: 'Dodatna usluga njege lica',
        l1: 'Masaža vlasišta',
        l2: 'Ultrazvučna lopatica',
      },
    },
    wax: {
      title: 'Depilacija',
      face: {
        title: 'Lice i obrve',
        l1: 'Usne',
        l2: 'Brada',
        l3: 'Oblikovanje obrva',
        l4: 'Boja za obrve',
      },
      bikini: {
        title: 'Bikini',
        l2: 'Bikini',
        l1: 'Brazilka',
      },
      body: {
        title: 'Tijelo',
        l1: 'Ispod pazuha',
        l2: 'Pola ruke',
        l3: 'Pola noge',
        l4: 'Cijela ruka',
        l5: 'Cijela noga',
        l6: 'Leđa ili prsa',
      },
    },
    treatments: {
      title: 'Tretmani',
      pedicure: {
        title: 'Manikura / Pedicura',
        l1: 'Manikura',
        l2: 'Spa manikura',
        l3: 'Pedikura',
        l4: 'Spa pedikura',
      },
      additional: {
        title: 'Dodatna usluga',
        l1: 'CND Shellac',
        l2: 'Soak-off Uklanjanje',
        l3: 'Lak za nokte',
        l4: 'Uklanjanje gela/dippinga/akrila',
      },
    },
  },
  gallery: {
    title: 'GALERIJA',
  },
  amenities: {
    entertainment: {
      title: 'ZABAVA',
      tv: 'Smart TV s kabelskim/satelitskim programom',
    },
    bedFeatures: {
      title: 'KREVET',
      sheets: 'Visokokvalitetna posteljina',
      pillow: 'Hipoalergenski jastuk',
      pillowMenu: 'Izbor jastuka',
      service: 'Turndown usluga',
    },
    bath: {
      title: 'KUPAONICA',
      shower: 'Kupaonica s kadom ili tuš-kabinom',
      hairdryer: 'Sušilo za kosu',
      cosmetic: 'Kozmetički asortiman',
      scale: 'Vaga',
      amenities: 'Sadržaji za duži boravak (na zahtjev)',
      heating: 'Podno grijanje u kupaonici',
    },
    office: {
      title: 'URED/TELEKOM',
      telephone: 'Telefon',
      wifi: 'Besplatni pristup bežičnom Internetu velike brzine',
      wakeUp: 'Buđenje (na zahtjev)',
      elePlug: 'Univerzalni električni priključak (na zahtjev)',
    },
    dining: {
      title: 'HRANA/PIĆE',
      wine: 'Boca vina dobrodošlice',
      minibar: 'Rashlađeni minibar',
      kettle: 'Kuhalo za kavu i čaj',
    },
    features: {
      title: 'ZNAČAJKE',
      smoke: 'Sobe za nepušače',
      ac: 'Klimatizacija',
      desk: 'Pisaći stol',
      light: 'Svjetlo za čitanje',
      safe: 'Elektronski sef (laptop „friendly“)',
      bathrobe: 'Ogrtač i papuče',
      laundry: 'Vreća za rublje',
    },
    additional: {
      title: 'DODATNE USLUGE',
      beach: 'Artikli za plažu (na zahtjev)',
      crib: 'Dječji krevetić (na zahtjev)',
      gifts: 'Dar dobrodošlice za dijete (informacije dostupne na recepciji)',
      pets: 'Usluge za kućne ljubimce (na zahtjev)',
    },
  },
  impressum: {
    country: 'Hrvatska',
    propName: 'Ime objekta',
    propAddress: 'Adresa objekta',
    compName: 'Ime kompanije',
    compAddress: 'adresa kompanije',
    vat: 'Pdv',
    commercialCourt: {
      title: 'Trgovački sud',
      p1: 'Tvrtka je upisana u sudski registar kod Trgovačkog suda u Splitu pod brojem 060086339.',
    },
    phone: 'Telefon',
  },
  footer: {
    smsInvoice: 'SMS Parking račun',
  },
};
