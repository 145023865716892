import ScrollAnimation from '../../../../utils/scrollAnimation';
import separator from '../../../../assets/imgs/separator.svg';

const Wax = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <>
      <div id='wax'>
        <div className='spa_about_img spa_about_img_rotate'>
          <img src={separator} alt='separator' />
        </div>
        <div className='container'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={500}
          >
            <h2 className='title_underline_light'>{lang.spa.wax.title}</h2>
            <div className='offers_section_row'>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
                <p>{lang.spa.wax.face.title}</p>
                <ul>
                  <li>{lang.spa.wax.face.l1} - 1O€</li>
                  <li>{lang.spa.wax.face.l2} - 1O€</li>
                  <li>{lang.spa.wax.face.l3} - 15€</li>
                  <li>{lang.spa.wax.face.l4} - 15€</li>
                </ul>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
                <p>{lang.spa.wax.body.title}</p>
                <ul>
                  <li>{lang.spa.wax.body.l1} - 15€</li>
                  <li>{lang.spa.wax.body.l2} - 20€</li>
                  <li>{lang.spa.wax.body.l3} - 20€</li>
                  <li>{lang.spa.wax.body.l4} - 25€</li>
                  <li>{lang.spa.wax.body.l5} - 35€</li>
                  <li>{lang.spa.wax.body.l6} - 35€</li>
                </ul>
              </ScrollAnimation>
            </div>
            <div className='offers_section_row'>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
                <p>{lang.spa.wax.bikini.title}</p>
                <ul>
                  <li>{lang.spa.wax.bikini.l1} - 20€</li>
                  <li>{lang.spa.wax.bikini.l2} - 3O€</li>
                </ul>
              </ScrollAnimation>

              <div></div>
            </div>
          </ScrollAnimation>
        </div>
        <div className='spa_about_img'>
          <img src={separator} alt='separator' />
        </div>
      </div>
    </>
  );
};

export default Wax;
