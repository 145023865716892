import ScrollAnimation from '../../../../utils/scrollAnimation';

const Treatments = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='treatments'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='title_underline_dark'>{lang.spa.treatments.title}</h2>
          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
              <p>{lang.spa.treatments.pedicure.title}</p>
              <ul>
                <li>{lang.spa.treatments.pedicure.l2} - 54€</li>
                <li>{lang.spa.treatments.pedicure.l1} - 36€</li>
                <li>{lang.spa.treatments.pedicure.l4} - 66€</li>
                <li>{lang.spa.treatments.pedicure.l3} - 54€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.treatments.additional.title}</p>
              <ul>
                <li>{lang.spa.treatments.additional.l1} - 25€</li>
                <li>{lang.spa.treatments.additional.l2} - 20€</li>
                <li>{lang.spa.treatments.additional.l4} - 30€</li>
                <li>{lang.spa.treatments.additional.l3} - 1O€</li>
              </ul>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Treatments;
