import ScrollAnimation from '../../../../utils/scrollAnimation';

const Masssages = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='masssages'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='title_underline_dark'>{lang.spa.massage.title}</h2>
          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
              <p>{lang.spa.massage.body.title}</p>
              <span>{lang.spa.massage.body.subtitle}</span>
              <ul>
                <li>60min - 120€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.massage.muscle.title}</p>
              <span>{lang.spa.massage.muscle.subtitle}</span>
              <ul>
                <li>25min - 60€</li>
                <li>50min - 84€</li>
                <li>80min - 120€</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
              <p>{lang.spa.massage.cora.title}</p>
              <span>{lang.spa.massage.cora.subtitle}</span>
              <ul>
                <li>25min - 48€</li>
                <li>50min - 84€</li>
                <li>80min - 120€</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
              <p>{lang.spa.massage.additional.title}</p>
              <ul>
                <li>{lang.spa.massage.additional.l1} 20min - 30€</li>
                <li>{lang.spa.massage.additional.l2} 25min - 40€</li>
                <li>{lang.spa.massage.additional.l3} 15min - 20€</li>
              </ul>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Masssages;
